@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bg-primary: 39, 40, 44;
    --btn-primary: 39, 40, 44;
    --bg-secondary: 245, 246, 247;
    --bg-tertiary: 238, 238, 238;
    --bg-active-nav: #141414;

    --text-primary: #24272a;
    --text-secondary: 238, 238, 238;
    --text-tertiary: 154, 154, 154;

    --border-theme-stroke: 50, 51, 54;
  }

  html[data-theme='blue'] {
    --bg-primary: 15, 48, 71;
    --btn-primary: 15, 48, 71;
    --bg-secondary: 245, 246, 247;
    --bg-tertiary: 238, 238, 238;
    --bg-active-nav: #141414;

    --text-primary: #24272a;
    --text-secondary: 238, 238, 238;
    --text-tertiary: 154, 154, 154;

    --border-theme-stroke: 24, 55, 76;
  }
  html[data-theme='red'] {
    --bg-primary: 68, 12, 10;
    --btn-primary: 68, 12, 10;
    --bg-secondary: 245, 246, 247;
    --bg-tertiary: 255, 255, 255;
    --bg-active-nav: #141414;

    --text-primary: #24272a;
    --text-secondary: 238, 238, 238;
    --text-tertiary: 154, 154, 154;

    --border-theme-stroke: 75, 33, 32;
  }
  html[data-theme='light'] {
    --bg-primary: 251, 251, 251;
    --btn-primary: 39, 40, 44;
    --bg-secondary: 245, 246, 247;
    --bg-tertiary: 255, 255, 255;
    --bg-active-nav: 20, 20, 20;

    --text-primary: #24272a;
    --text-secondary: 116, 118, 120;
    --text-tertiary: 154, 154, 154;

    --border-theme-stroke: 237, 237, 237;
  }

  html {
    font-family: 'TT-Commons', sans-serif;
    letter-spacing: -0.2px;
    line-height: 120%;
    font-weight: 500;
    /* font-size: 15px; */
    color: #242424;
  }

  .container-auth {
    @apply w-full max-w-[1102px] mx-auto;
  }
  .container-solutions {
    @apply w-full max-w-[1440px] mx-auto;
  }
  .text-tertiary,
  .text-secondary,
  .text-grey-content-tertiary,
  .text-grey-content-secondary {
    @apply font-medium;
  }

  .header2 {
    @apply text-[2rem] leading-[2rem];
  }
  .header3 {
    @apply text-[1.625rem] leading-[2rem];
  }
  .header4 {
    @apply text-xl2 leading-2xl2;
  }
  .header5 {
    @apply text-xl leading-2xl;
  }
  .header6 {
    @apply text-lg leading-xl1;
  }
  .paragraph1 {
    @apply text-base1 leading-xl1;
  }
  .paragraph2 {
    @apply text-lg leading-xl2;
  }

  @font-face {
    /* font-extralight */
    font-family: 'TT-Commons';
    font-weight: 100;
    src: url('./assets/fonts/TTCommonsThin.otf') format('truetype');
  }

  @font-face {
    /* font-extralight */
    font-family: 'TT-Commons';
    font-weight: 200;
    src: url('./assets/fonts/TTCommonsExtraLight.otf') format('truetype');
  }
  @font-face {
    /* font-light */
    font-family: 'TT-Commons';
    font-weight: 300;
    src: url('./assets/fonts/TTCommonsLight.otf') format('truetype');
  }
  @font-face {
    /* font-normal */
    font-family: 'TT-Commons';
    font-weight: 500;
    src: url('./assets/fonts/TTCommonsRegular.otf') format('truetype');
  }
  @font-face {
    /* font-medium */
    font-family: 'TT-Commons';
    font-weight: 500;
    src: url('./assets/fonts/TTCommonsMedium.otf') format('truetype');
  }
  @font-face {
    /* font-semi bold */
    font-family: 'TT-Commons';
    font-weight: 600;
    src: url('./assets/fonts/TTCommonsDemiBold.otf') format('truetype');
  }
  @font-face {
    /* font-bold */
    font-family: 'TT-Commons';
    font-weight: 700;
    src: url('./assets/fonts/TTCommonsBold.otf') format('truetype');
  }
  @font-face {
    /* font-extra bold */
    font-family: 'TT-Commons';
    font-weight: 800;
    src: url('./assets/fonts/TTCommonsExtraBold.otf') format('truetype');
  }
  @font-face {
    /* font-black */
    font-family: 'TT-Commons';
    font-weight: 900;
    src: url('./assets/fonts/TTCommonsBlack.otf') format('truetype');
  }
}

.gradient1 {
  background: linear-gradient(
    180deg,
    rgba(255, 250, 234, 0.69) 0%,
    rgba(255, 252, 244, 0) 100%
  );
  background: #f7f4e9;
}

.gradient2 {
  background: linear-gradient(180deg, rgba(254, 254, 254, 0) 0%, #efefef 100%);
}

input:autofill {
  border: 0;
}

.password input[type='password'] {
  font-family: Verdana;
  letter-spacing: 0.125em;
}

input:-webkit-autofill {
  border: 0;
}

.justify-normal {
  justify-content: normal;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-stretch {
  justify-content: stretch;
}

.shopper-trust-index-card {
  background: linear-gradient(
      180deg,
      rgba(224, 224, 224, 0) 0%,
      rgba(224, 224, 224, 0.12) 100%
    ),
    var(--background-secondary, #fbfbfb);
}
@keyframes kryt {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.login-form {
  animation: 500ms ease-in 0s 1 normal none running formA;
}

@keyframes formA {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.circleA {
  animation: 200ms ease-in 0s 1 normal none running circleAnimate;
}

@keyframes circleAnimate {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

.checkout_refund_gradient {
  background: linear-gradient(
      180deg,
      rgba(224, 224, 224, 0) 0%,
      rgba(224, 224, 224, 0.12) 100%
    ),
    var(--background-secondary, #fbfbfb);
}

.grid-reveal {
  display: grid;
  grid-template-rows: 0fr;
  padding: 0;
  transition: all 300ms;
}
.grid-reveal > div {
  overflow: hidden;
}
.group .grid-reveal {
  grid-template-rows: 1fr;
  padding: 24px 16px;
}
.apexcharts-tooltip {
  background-color: #4c4e50;
  color: #fff;
}

.apexcharts-xaxistooltip {
  background: #fbfbfb !important;
  border: 0px !important;
}

:root {
  --toastify-color-light: rgba(39, 40, 44, 0.94);
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 448px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #eeeeee;
  --toastify-text-color-dark: #fff;

  /* //Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 44px;
}

input,
button {
  @apply focus:!outline-none;
}

[type='search']::-webkit-search-cancel-button {
  appearance: none;
  height: 15px;
  width: 15px;
  background-image: url('./assets/images/crossIcon.svg');
  background-size: contain;
  cursor: pointer;
}
